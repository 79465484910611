import { useDeviceImport } from "client/hooks/import/useDeviceImport";
import { HandleFile, UploadSettings } from "client/types/HandleFile";
import { useToast } from "components/common/Toast/useToast";
import { setFileState, removeFile, addImage } from "store/features/imageUploader/actions";
import { createImportTask } from "store/features/imageUploader/extraReducers/createImportTask";
import { imageUploaderSelector } from "store/features/imageUploader/selectors/imageUploaderSelector";
import { FileState, ImageUpload, UploadType } from "store/features/imageUploader/types";
import { store, useAppDispatch, useAppSelector } from "store/store";
import { capitalize } from "client/utils/capitalize";
import { Task } from "@freeconvert/freeconvert-node/dist/types";
import { FileData } from "client/types/FileData";
import { useExternalImport } from "client/hooks/import/useExternalImport";
import { getRandomId } from "client/utils/getRandomId";
import { useDragUpload } from "client/hooks/utils/useDragUpload";
import { DEFAULT_TOAST_SHOW_TIME } from "components/common/Toast/ToastRenderer";
import { HandleCreateImportTaskSuccess, useCreateImportTask } from "client/utils/useCreateImportTask";
import { Socket } from "socket.io-client";
import { getSocket } from "client/utils/getSocket";
import { sleep } from "client/utils/sleep";
import { useBatchImport } from "client/hooks/import/useBatchImport";
import { UPLOADER_CONTAINER_ID } from "./ImageUploader";
import { useEffect, useMemo } from "react";
import { translateFunc } from "client/utils/translateFunc";

export interface ImageUploaderOptions {
    maxUploads: number;
    supportedExtensions: string[];
    shouldSkipImport?: boolean;
    skipImportAfterTaskCreate?: boolean;
    filterFiles?: (files: FileData[]) => FileData[];
}

let socket: Socket | null = null;
const importingStatuses: Array<FileState["status"]> = ["creating_task", "task_created", "importing"];
let imagesCache: ImageUpload[] = [];

export const useImageUploader = (options: ImageUploaderOptions, deleteAllImages: () => void) => {
    const { images, uploadType } = useAppSelector(imageUploaderSelector);
    imagesCache = images;
    const dispatch = useAppDispatch();
    const { toast, removeBatch } = useToast();

    const { handleCreateImportTask } = useCreateImportTask();

    const handleCreateImportTaskSuccess: HandleCreateImportTaskSuccess<ImageUpload> = async (
        _image,
        task,
        file,
        uploadType,
    ) => {
        if (uploadType === "device") {
            if (options.skipImportAfterTaskCreate) {
                onComplete(task);
                return;
            }

            await handleDeviceImport(file, task, {});
            return;
        }

        await handleExternalImport(task, {});
    };

    const onStart = (task: Task, _metaData: any, progress?: FileState["progress"]) => {
        dispatch(setFileState({ task, fileState: { progress: progress ?? 0, status: "importing" } }));
    };

    const onComplete = async (task: Task) => {
        dispatch(
            setFileState({
                task,
                fileState: { status: "imported", progress: 100 },
            }),
        );
        await sleep();
        proceedNextImports();
    };

    const onFailureText = capitalize(translateFunc("upload-failed"));
    const onFailure = async (_task: Task) => {
        dispatch(removeFile({ taskId: _task.id }));
        toast({
            closeable: true,
            position: "top-right",
            status: "error",
            text: onFailureText,
            time: DEFAULT_TOAST_SHOW_TIME,
        });
        await sleep();
        proceedNextImports();
    };

    const onOfflineLimitReachText = capitalize(translateFunc("upload-cancelled"));
    const onOfflineLimitReach = (_task: Task) => {
        dispatch(removeFile({ taskId: _task.id }));
        toast({
            closeable: true,
            position: "top-right",
            status: "error",
            text: onOfflineLimitReachText,
            time: DEFAULT_TOAST_SHOW_TIME,
        });
    };

    const onProgress = (_task: Task, progress: number, speed?: string) => {
        dispatch(
            setFileState({
                task: _task,
                fileState: {
                    progress,
                    speed,
                },
            }),
        );
    };

    const handleFile: HandleFile = async (_files, uploadType, settings) => {
        if (_files.length === 0) return;
        const files = options.filterFiles ? options.filterFiles(_files) : _files;
        if (!validateHandleFile(files)) return;
        files.forEach(async (file, index) => {
            const image = await handleNewImage(file, uploadType, settings);
            if (!image) return;
            await handleBatchedSingleFile(image, index);
        });
        scrollToFirstUpload();
    };

    const scrollToFirstUpload = async () => {
        if (options.shouldSkipImport) return;
        await sleep(); // for removing ui lag
        document.getElementById(UPLOADER_CONTAINER_ID)?.scrollIntoView({ behavior: "smooth" });
    };

    const validateHandleFile = (files: FileData[]): boolean => {
        isSingleUpload(files.length) && handleCancelAllImports();
        if (!hasExceededTotalImagesLimit(files.length)) return true;
        showMaxFileLimitErrorToast();
        return false;
    };

    const isSingleUpload = (inputLength: number) => options.maxUploads === 1 && inputLength === 1;

    const hasExceededTotalImagesLimit = (inputLength: number) => inputLength > options.maxUploads - imagesCache.length;

    const uploadLimitText =
        options.maxUploads > 1
            ? translateFunc("common:multiple-max-upload-limit", { limit: options.maxUploads })
            : translateFunc("common:single-uploader-limit");
    const showMaxFileLimitErrorToast = () => {
        toast({
            closeable: true,
            position: "top-right",
            status: "error",
            batch: "max-upload-limit",
            text: uploadLimitText,
            time: DEFAULT_TOAST_SHOW_TIME,
        });
    };

    const handleNewImage = async (file: FileData, uploadType: UploadType, settings: UploadSettings[UploadType]) => {
        socket = socket ?? (await getSocket());
        if (store.getState().imageUploader.images.length >= options.maxUploads) {
            showMaxFileLimitErrorToast();
            return;
        }
        const image: ImageUpload = {
            id: getRandomId(),
            file: file,
            fileState: {
                status: "added",
                progress: options.shouldSkipImport ? 100 : "continuous",
            },
            task: null,
            upload: {
                type: uploadType,
                settings,
            },
        };
        dispatch(addImage(image));

        return image;
    };

    const handleImportCancel = (task: Task) => {
        dispatch(removeFile({ taskId: task.id }));
        uploadType === "device" && handleDeviceCancel(task);
    };

    const handleCancelAllImports = () => {
        deleteAllImages();
        handleCancelAllDeviceImports();
    };

    const onDisconnectText = `${translateFunc("you-are-offline")} ${translateFunc("trying-to-reconnect")}`;
    const onDisconnect = () => {
        toast({
            closeable: true,
            position: "top-right",
            status: "error",
            batch: "disconnect",
            text: onDisconnectText,
        });
    };

    const onReconnectText = useMemo(() => translateFunc(`on-reconnect-text`), []);
    const onReconnect = () => {
        toast({
            closeable: true,
            position: "top-right",
            status: "success",
            batch: "reconnect",
            text: onReconnectText,
            time: DEFAULT_TOAST_SHOW_TIME,
        });
        removeBatch("disconnect");
    };

    const onImportStart = (image: ImageUpload) => {
        handleCreateImportTask(
            image,
            image.file,
            image.upload.type,
            image.upload.settings,
            createImportTask,
            handleCreateImportTaskSuccess,
        );
    };

    const getImportingImages = (): ImageUpload[] =>
        imagesCache.filter((image) => importingStatuses.includes(image.fileState.status));

    const getAddedImages = (): ImageUpload[] => imagesCache.filter((image) => image.fileState.status === "added");

    const { handleBatchedSingleFile, proceedNextImports } = useBatchImport(
        onImportStart,
        getImportingImages,
        getAddedImages,
        !!options.shouldSkipImport,
    );

    const { handleDeviceCancel, handleDeviceImport, handleCancelAllDeviceImports } = useDeviceImport({
        onStart,
        onComplete,
        onFailure,
        onOfflineLimitReach,
        onProgress,
        onDisconnect,
        onReconnect,
    });

    const { handleExternalImport } = useExternalImport({
        onStart: (task) => onStart(task, "continuous"),
        onComplete,
        onFailure,
        onOfflineLimitReach,
    });

    const { isDragging } = useDragUpload(typeof window !== "undefined" ? document.body : null, handleFile, {
        supportedExtensions: options.supportedExtensions,
    });

    useEffect(() => {
        return () => {
            imagesCache = [];
        };
    }, []);

    return {
        images,
        uploadType,
        handleFile,
        handleImportCancel,
        handleCancelAllImports,
        isDragging,
    };
};
